import { TextField as MuiTextField, TextFieldProps } from '@material-ui/core';
import useEventCallback from 'app/hooks/useEventCallback';
import { useField } from 'formik';
import React, { useMemo, useState } from 'react';

interface OwnProps {
  name: string;
  maxLength?: number;
}

type Props = OwnProps & TextFieldProps;

const TextField: React.FunctionComponent<Props> = ({
  name,
  onBlur,
  onChange,
  maxLength,
  inputProps,
  error,
  helperText,
  autoFocus,
  ...rest
}) => {
  // https://github.com/formium/formik/issues/2062
  const [alreadyBlured, setAlreadyBlured] = useState<boolean>(false);
  const [field, meta, helpers] = useField<string>({
    name,
    type: rest.type,
  });

  const modifiedInputProps = useMemo(
    () => ({
      maxLength,
      name,
      ...inputProps,
    }),
    [inputProps, maxLength, name],
  );

  const onBlurCallback = useEventCallback<TextFieldProps['onBlur']>(
    (event) => {
      if (autoFocus && !meta.touched && !field.value && !alreadyBlured) {
        setAlreadyBlured(true);
        return;
      }

      field.onBlur(event);
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur, field, meta, autoFocus, alreadyBlured, setAlreadyBlured],
  );

  const onChangeCallback = useEventCallback<TextFieldProps['onChange']>(
    (event, ...rest) => {
      field.onChange(event);
      if (onChange) {
        onChange(event, ...rest);
      }
    },
    [onChange, helpers],
  );

  const showError = meta.touched && !!meta.error;

  return (
    <MuiTextField
      onBlur={onBlurCallback}
      onChange={onChangeCallback}
      inputProps={modifiedInputProps}
      error={error || showError}
      helperText={(showError && meta.error) || helperText}
      value={field.value}
      id={name}
      autoFocus={autoFocus}
      {...rest}
    />
  );
};

TextField.displayName = 'TextField';

export default React.memo<Props>(TextField);
