import React from 'react';
import { Form as FormikForm, FormikFormProps } from 'formik';

interface OwnProps {}

type Props = OwnProps & FormikFormProps;

const Form: React.ForwardRefRenderFunction<HTMLFormElement, Props> = (
  { action = '#', method = 'post', noValidate = true, ...rest },
  ref,
) => {
  return <FormikForm ref={ref} action={action} method={method} noValidate={noValidate} {...rest} />;
};

Form.displayName = 'Form';

export default React.forwardRef<HTMLFormElement, Props>(Form);
