import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

interface OwnProps {
  className?: string;
  error?: React.ReactNode;
}

type Props = OwnProps;

const ServerErrorSnackbar: React.FunctionComponent<Props> = ({ className, error }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, className)}>
      <Typography variant="h6" component="h6">
        Chyba!
      </Typography>
      Počas spracovania nastala chyba na serveri.
      <br />
      Nahláste prosím túto chybu administrátorom.
      <br />
      <br />
      Ďakujeme za trpezlivosť a pochopenie :)
    </div>
  );
};

ServerErrorSnackbar.displayName = 'ServerErrorSnackbar';

export default React.memo<Props>(ServerErrorSnackbar);
