import ServerErrorSnackbar from 'app/components/snackbars/ServerErrorSnackbar';
import ValidationSnackbar from 'app/components/snackbars/ValidationSnackbar';
import { GraphQLError } from 'graphql';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import React from 'react';

export function displayError(
  graphQLErrors: GraphQLError[],
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
): SnackbarKey {
  let key: SnackbarKey;
  if (graphQLErrors.length) {
    const badUserInputErrors = getBadUserInputErrors(graphQLErrors);
    const internalServerErrors = getInternalServerErrors(graphQLErrors);

    if (badUserInputErrors.length) {
      // Hide toasts on server
      const errorMessage =
        badUserInputErrors.length === 1 ? (
          badUserInputErrors[0]
        ) : (
          <ul>
            {badUserInputErrors.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        );

      key = enqueueSnackbar(<ValidationSnackbar error={errorMessage} showTitle={badUserInputErrors.length > 1} />, {
        variant: 'error',
      });
    }

    if (internalServerErrors.length) {
      key = enqueueSnackbar(<ServerErrorSnackbar />, {
        variant: 'error',
      });
    }
  }

  return key;
}

function getErrorsByCode(graphQLErrors: GraphQLError[], code: 'BAD_USER_INPUT' | 'INTERNAL_SERVER_ERROR'): string[] {
  return graphQLErrors.filter((e: any) => e.extensions && e.extensions.code === code).map((m) => m.message);
}

export function getBadUserInputErrors(graphQLErrors: GraphQLError[]): string[] {
  return getErrorsByCode(graphQLErrors, 'BAD_USER_INPUT');
}

export function getInternalServerErrors(graphQLErrors: GraphQLError[]): string[] {
  return getErrorsByCode(graphQLErrors, 'INTERNAL_SERVER_ERROR');
}
