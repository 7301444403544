import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

interface OwnProps {
  className?: string;
  error?: React.ReactNode;
  showTitle?: boolean;
}

type Props = OwnProps;

const ValidationSnackbar: React.FunctionComponent<Props> = ({ className, error, showTitle = true }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, className)}>
      {showTitle && (
        <Typography variant="h6" component="h6">
          Chyba!
        </Typography>
      )}
      {error || 'Vo formulári sa nachádzajú chyby!'}
    </div>
  );
};

ValidationSnackbar.displayName = 'ValidationSnackbar';

export default React.memo<Props>(ValidationSnackbar);
